<template>
    <div class="selectContacts">
        <van-checkbox-group v-model="result">
            <div class="scrollBox">
                <scroll v-if="!loading" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                    <div class="moduleCodePage">
                        <template v-for="(item,index) in listData">
                            <span :key="index">
                                <div v-if="item.mailAddress" :class="nodata=false">
                                    <template v-for="(items,indexs) in item.mailAddress">
                                        <div :key="indexs" class="listBox" @click="changeOption(index+'_'+indexs)">
                                            <van-checkbox :name="index+'_'+indexs" style="position: absolute;left: 15px;transform: translateY(50%);">
                                            </van-checkbox>
                                            <div :key="indexs">{{item.contName}}</div>
                                            <div style="color:#606266;">{{items}}</div>
                                        </div>
                                    </template>
                                </div>

                            </span>
                        </template>
                    </div>

                </scroll>
                <loading-new :loading="loading"></loading-new>

            </div>
        </van-checkbox-group>
        <nodata v-if="nodata" title="暂无联系人" img="noData"></nodata>
    </div>
</template>
<script>
import Scroll from '@/components/Scroll/index'
import loadingNew from '../../../Client/CustPublic/LoadingVue/index.vue'
export default {
    name: 'selectContacts',
    props: {},
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: '加载更多',
            pullUpLoadNoMoreTxt: '没有更多数据了',
            startY: 0,
            scrollToX: 0,
            scrollToY: -200,
            scrollToTime: 700,
            scrollToEasing: 'bounce',
            scrollToEasingOptions: ['bounce', 'swipe', 'swipeBounce'],
            page: {
                total: 0,
                from: 0,
                size: 10
            },
            loading: true,
            listData: [],
            checked: false,
            result: [],
            sort: 'createDate',
            nodata: true
        }
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    components: {
        'Scroll': Scroll,
        'loading-new': loadingNew
    },
    created() {
        this.pageInit()
    },
    mounted() {

    },
    methods: {
        changeOption(item) {
            let index = this.result.indexOf(item)
            if (index >= 0) {
                this.result.splice(index, 1)
            } else {
                this.result.push(item)
            }
        },
        getnodata() {
            this.nodata = false
        },
        // 停止冒泡
        stopAction(e) {
            e.stopPropagation()
        },
        setRightMenu() {
            let rightMenu = this.Global.utils.rightMenu.setMenu('完成')
            rightMenu.onClick(() => {
                let list = []
                for (let index = 0; index < this.result.length; index++) {
                    let resultArray = this.result[index].split('_')
                    let element = resultArray[0]
                    let element2 = resultArray[1]
                    let obj = {
                        mailAddress: this.listData[element].mailAddress[element2],
                        custId: this.listData[element].custId,
                        name: this.listData[element].contName
                    }
                    list.push(obj)
                }
                this.Global.utils.setItem('ContactsArray', JSON.stringify(list))
                this.result = []
                this.$router.go(-2)
            })
        },
        async pageInit() {
            if (this.$route.name == 'selectContacts') {
                this.nodata = true
                this.result = []
                this.setHeaderTitle('选择联系人')
                this.setRightMenu()
                this.listData = await this.getListData()
            }
        },
        async getListData(obj) {
            this.loading = true
            this.listData = []
            let data = {
                custId: this.$route.params.id,
                moduleCode: 'BF003',
                order: 'desc',
                size: this.page.size,
                sort: this.sort,
                searchType: 'allList'
            }
            if (obj) {
                data = Object.assign(data, obj)
                this.otherObj = obj
                this.page.from = 0
            } else {
                data = Object.assign(data, this.otherObj)
            }
            data.from = this.page.from
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.document_generalOperate_get, { params: data })
            if (res.data.code.toString() === this.Global.config.RES_OK) {
                this.loading = false
                this.page.total = res.data.data.totalNum
                return res.data.data.list
            } else {
                this.$toast.fail(res.data.msg)
                return []
            }
        },
        async onPullingDown() {
            this.page.from = 0
            this.checkedItems = []
            this.listData = await this.getListData()
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        async onPullingUp() {
            if (this.listData.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.listData.length < this.page.total) {
                this.page.from += 10
                let listData = await this.getListData()
                this.listData = this.listData.concat(listData)
                this.$nextTick(() => {
                    if (this.listData.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        }

    },
    watch: {
        $route(to, form) {
            this.pageInit()
        },
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        },
        startY() {
            // this.rebuildScroll()
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
